var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('span',{staticClass:"brand-logo justify-content-center align-items-center"},[_c('img',{attrs:{"src":require('@/assets/images/logo/papirai-logo.svg'),"height":"25"}}),_c('h2',{staticClass:"brand-text ml-1"},[_vm._v(" papir.ai ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('registerHeader'))+" 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('registerMessage'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('username'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","vid":"username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('name')},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('required'))+" ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Soyadı","label-for":"register-lastname"}},[_c('validation-provider',{attrs:{"name":"Lastname","vid":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false:null,"placeholder":_vm.$t('lastname')},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('required'))+" ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('required'))+" ")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","value":"accepted","name":"checkbox-1","unchecked-value":"not_accepted"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('b-link',[_vm._v(_vm._s(_vm.$t('privacyAndTerms')))]),_vm._v(" "+_vm._s(_vm.$t('readAndAccept'))+" ")],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || (_vm.status === 'not_accepted') || _vm.loading}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('register')))])],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('alreadyHaveAccount')))]),_c('b-link',{attrs:{"to":{name:'Auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('loginPageRedirect')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
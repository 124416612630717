<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <span class="brand-logo justify-content-center align-items-center">
                <img :src="require('@/assets/images/logo/papirai-logo.svg')" height="25"
                />
                <h2 class="brand-text ml-1">
                    papir.ai
                </h2>
            </span>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
          lg="7"
          class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
              fluid
              :src="imgUrl"
              alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
          lg="5"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('registerHeader') }} 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('registerMessage') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
              ref="registerForm"
              #default="{invalid}"
          >
            <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                  :label="$t('username')"
                  label-for="register-username"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Username"
                    vid="username"
                    rules="required"
                >
                  <b-form-input
                      id="register-username"
                      v-model="username"
                      name="register-username"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('name')"
                  />
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>


              <b-form-group
                  label="Soyadı"
                  label-for="register-lastname">
                <validation-provider
                    #default="{ errors }"
                    name="Lastname"
                    vid="lastname"
                    rules="required"
                >
                  <b-form-input
                      id="register-lastname"
                      v-model="lastname"
                      name="register-lastname"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('lastname')"
                  />
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                  :label="$t('email')"
                  label-for="register-email"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                >
                  <b-form-input
                      id="register-email"
                      v-model="userEmail"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john@example.com"
                  />
                  <small v-if="errors.length > 0" class="text-danger">
                    {{ $t('required') }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox id="register-privacy-policy"
                                 v-model="status"
                                 value="accepted"
                                 name="checkbox-1"
                                 unchecked-value="not_accepted">
                  <b-link>{{ $t('privacyAndTerms') }}</b-link>
                  {{ $t('readAndAccept') }}
                </b-form-checkbox>
              </b-form-group>

              <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid || (status === 'not_accepted') || loading">
                <b-spinner small v-if="loading"/>
                <span v-else>{{ $t('register') }}</span>
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('alreadyHaveAccount') }}</span>
            <b-link :to="{name:'Auth-login'}">
              <span>&nbsp;{{ $t('loginPageRedirect') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--                variant="facebook"-->
          <!--                href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="FacebookIcon"/>-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--                variant="twitter"-->
          <!--                href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="TwitterIcon"/>-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--                variant="google"-->
          <!--                href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="MailIcon"/>-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--                variant="github"-->
          <!--                href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="GithubIcon"/>-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {AuthenticationError} from "@/services/UserAccountService";
import i18n from "@/libs/i18n";

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BSpinner,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: 'accepted',
      username: '',
      lastname: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.$store.dispatch('auth/registerUser', {
            firstName: this.username,
            lastName: this.lastname,
            email: this.userEmail,
          }).then(response => {
            this.loading = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: i18n.t('messages.user.saveAccountMessage'),
                icon: 'CheckIcon',
                variant: 'success',
              },
            }, {position: 'top-center'});
            this.$router.push({name: 'Auth-login'})
          }).catch(error => {
                this.loading = false
                if (error instanceof AuthenticationError) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('messages.user.failedOnSave'),
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                      text: error.message
                    },
                  }, {position: 'top-center'});
                  return
                }

                if (!error.response) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: i18n.t('messages.user.failedOnLogin'),
                      text: i18n.t('messages.checkYourInternetConnection'),
                      icon: 'AlertTriange Icon',
                      variant: 'danger',
                    },
                  }, {position: 'top-center'})
                }
              }
          )
        }
      }).catch(reason => this.$refs.registerForm.setErrors(reason.response.data.error))
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
